import AxiosPromotion from './index';
import config from '../../config';
import axios from 'axios';
import { commonConstant } from '../../common';

export const getCustomerFOA = async (userId) => {
  try {
    const customer = sessionStorage.getItem('customer');
    if (customer) {
      return JSON.parse(customer);
    }

    const { data } = await axios.get(
      `${config.API_URL}/promotion/foa/user/${userId}`,
      {
        headers: {
          'Authorization': commonConstant.dummyToken
        }
      }
    );

    sessionStorage.setItem('customer', JSON.stringify(data));

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const validateCaptcha = async (token) => {
  try {
    const { data } = await axios.get(
      `${config.API_URL}/promotion/foa/validate/captcha/${token}`,
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const validateRegister = async (token, phoneNumber) => {
  try {
    const { data } = await axios.get(
      `${config.API_URL}/promotion/foa/validate/regis/${phoneNumber}`,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const generateOTPregis = async (token, phoneNumber, cartId) => {
  try {
    const { data } = await axios.post(
      `${config.API_URL}/promotion/foa/otp/regis`,
      {
        phoneNumber,
        cartId,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const verifiedOtp = async (token, cartId, pinCode) => {
  try {
    const { data } = await axios.post(
      `${config.API_URL}/promotion/foa/otp/verify`,
      {
        cartId,
        pinCode,
      },
      { headers: { Authorization: `Bearer ${token}` }}
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const prepareFOAcart = async (token, uid, order, guid) => {
  try {
    const { data } = await axios.post(
      `${config.API_URL}/promotion/foa/prepare/order`,
      {
        order,
        guid,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const registerFOA = async (token, order, cart, guid, uid) => {
  try {
    const { data } = await axios.post(
      `${config.API_URL}/promotion/foa/regis`,
      {
        order,
        cart,
        guid,
        uid,
      },
      { headers: { Authorization: `Bearer ${token}` }}
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const orderFOA = async (cart, uid) => {
  try {
    const { data } = await axios.post(`${config.API_URL}/promotion/foa/order`, {
      cart,
      uid,
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
