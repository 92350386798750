import React, {useEffect, useState} from 'react';
import { commonConstant } from '../../common';
import './CartPromotion.scss';
import { createCartPublic, getCart, getCartPublic } from '../../services/promotion/utils.service';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCartData } from '../../actions/cartAction';

const CartIcon = (props) => {
  const [cartCount, setCartCount] = useState(0);
  const history=useHistory();

   useEffect(() => {
    async function fetchCustData(){
    let customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    //as current Redux is a sync call, we required to check isFOAEmpty 
    if(props?.isFOAEmpty){
      customerFOA =null;
    }
    const guid = sessionStorage.getItem('guid');
    const cart = !customerFOA ? (props?.data.length > 0 ?props.data:await props.getCartData("FULL")) : guid
        ? await getCartPublic(guid) : await createCartPublic();
    if (Array.isArray(cart)) {
      const count = cart[0].entries.reduce((total, item) =>
          total + (item.kitEntryCode || item.isGiveAway ? 0 : item.quantity || 0),
        0,
      );
      setCartCount(count);
    }
  } fetchCustData();     
  }, []);

  const goToCart = () => {
    history.push(commonConstant.pathCartPromotion);
  }

  return (
    <div className="icon-button" onClick={goToCart}>
      <img src="/images/promotion/icon_button.png" />
      <span className="circle-notification">
        <span className="notification-text">{cartCount > 99 ? `+99` : cartCount}</span>
      </span>
    </div>
  )

};

const mapStateToProps = (state) => {
  return {
    data: state.cartApi.data,
    loading: state.cartApi.loading,
    error: state.cartApi.error,
  };
};

const mapDispatchToProps = (dispatch)=> {
  return {
    getCartData: (fields) => dispatch(getCartData(fields)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(CartIcon);