import axios from 'axios';
import config from '../../config';
import { commonConstant } from '../../common';

const liff = window.liff;

export const liffFacadeToken = async () => {
  try {
    const profile = await liffGetProfile();
    const { data } = await axios.post(
      `${config.API_URL}/auth-promotion/access_token`,
      { uid: profile.userId },
      {
        headers:{
          Authorization : commonConstant.dummyToken
        },
        timeout: process.env.NETWORK_TIMEOUT,
      }
    );
    if (data.error) {
      sessionStorage.setItem('facadeTokenMDMSError', true);
      await axios.post(
        `${config.API_URL}/utils/create-logs`,
        {
          mdmsError : data.error,
          user: profile?.userId
        },
        {
          timeout: process.env.NETWORK_TIMEOUT,
        }
      )
    }
    return data?.data ? data?.data : data?.error;

  } catch (error) {
    if (error.code === 'ECONNABORTED') {
      console.error('Request timed out');
      alert('Request timed out');
    } else {
      console.error('Error fetching facade token:', error);
      alert('An error occurred, please try again.');
    }
    throw error;
  }
};

export const getFacadeAccessToken = async () => {
  return new Promise((resolve) => {
    liff.ready.then(() => {
      liffFacadeToken().then((token) => {
        resolve(token);
      });
    });
  });
};

export const liffAccesstoken = async () => {
  const profile = await liffGetProfile();
  const response = await fetchLbcTokenApi({profile});
  return response.token;
};

export const liffGetProfile = async () => {
  let profile = getItem("lineProfile");
  if(!profile){
    profile = await liff.getProfile();
    sessionStorage.setItem("lineProfile",JSON.stringify(profile));
  }
  return profile;
};


const getItem = (key) => {
  const itemStr = sessionStorage.getItem(key);
  return itemStr ? JSON.parse(itemStr): null;
};

const getAuthToken = async (data) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/token`, data);
    const expiresIn = response.data.expiresIn;
    setItemWithExpiry(response.data, expiresIn);
    return response.data;
  } catch(error) {
    throw error;
  }
};

export const fetchLbcTokenApi = async ({profile = null , params = null}) => {
  let cachedResponse = getItemWithExpiry("lbcToken");
  if(!cachedResponse) {
    let code = profile ? profile 
                : (params && typeof params != 'undefined' 
                  && typeof params.code != 'undefined') ? { code: params.code } 
                : await liffGetProfile();
    cachedResponse = await getAuthToken(code);
  }
  return cachedResponse;

}

export const setItemWithExpiry = (value, ttl) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 1000, // ttl is in seconds, convert to milliseconds
  };
  sessionStorage.setItem("lbcToken", JSON.stringify(item));
};

const getItemWithExpiry = (key) => {
  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    sessionStorage.removeItem(key);
    sessionStorage.removeItem("lineProfile");
    return null;
  }
  return item.value;
};

export const getAccessToken = async () => {
  return new Promise((resolve) => {
    liff.ready.then(() => {
      liffAccesstoken().then((token) => {
        resolve(token);
      });
    });
  });
};
