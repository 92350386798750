import config from '../../config';
import { ChallengeList } from '../../model';
import { ChallengeProfile } from '../../model/ChallengeProfile.model';
import AxiosHealthWellness from '../healthWellness';

export const getChallengeList = async (params) => {
  try {
    const res = await AxiosHealthWellness.get(`${config.API_URL}/hnw/challenge/`, {
      params,
    });
    if (res?.data) {
      return res.data.map((el) => new ChallengeList(el));
    }
    return [];
  } catch (error) {
    throw new Error(error);
  }
};

export const getChallengeDetail = async (params) => {
  try {
    const res = await AxiosHealthWellness.get(
      `${config.API_URL}/hnw/challenge/${params}`,
    );
    if (res?.data) {
      return new ChallengeList(res?.data);
    }
    return [];
  } catch (error) {
    throw new Error(error);
  }
}

export const getChallengeProfile = async (params) => {
  try {
    const res = await AxiosHealthWellness.get(
      `${config.API_URL}/hnw/challenge/profile/${params}`,
    );
    if (res?.data) {
      return res.data.map((el) => new ChallengeProfile(el));
    }
    return [];
  } catch (error) {
    throw new Error(error);
  }
}
