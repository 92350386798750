import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import "./insertW.scoped.scss";
import Spinner from '../components/Spinner'
import axios from "axios";
import Swal from "sweetalert2";
import { useSwipeable } from 'react-swipeable';
const InsertW = (props) => {
    const history = useHistory();
    let User = localStorage.getItem("User");
    let [loading2, setLoading2] = useState(false);
    const cover_user = User ? JSON.parse(User ? User : "") : '';

    useEffect(() => {
        getStore();
    }, []);

    const getStore = () => {
        setLoading2(true)
        axios({
            method: "get",
            url: `${process.env.REACT_APP_API_URL_UAT}/assessment/customerBMI`,
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${cover_user.token}`
            },
        }).then((res) => {
            setLoading2(false)
            setGender(res.data.data.gender);
            setWeight(res.data.data.weight);
            setAge(res.data.data.age);
            setHeight(res.data.data.height);
            setWaist(res.data.data.waistline);
        })
            .catch((err) => {
                setLoading2(false)
            });
    };
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [waist, setWaist] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');

    const handleWeightChange = (e) => {
        const value = e.target.value;

        // กรองเอาเฉพาะตัวเลขและทศนิยมเท่านั้น
        const filteredValue = value.replace(/[^0-9.]/g, "");

        // ตรวจสอบหากความยาวของค่าที่กรองแล้วมีความยาวเกิน maxLength ให้ตัดค่า
        let truncatedValue = filteredValue.slice(0, 9); // ปรับ maxLength เป็น 9 ตามตัวอย่าง

        // ตรวจสอบหากมีจุดทศนิยมในค่าที่ตัดแล้ว
        const dotIndex = truncatedValue.indexOf('.');

        // หากมีจุดทศนิยมอยู่หลายจุดในค่าที่ตัดแล้ว ให้ลบจุดทศนิยมที่เกิน 1 จุดออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).indexOf('.') !== -1) {
            truncatedValue = truncatedValue.slice(0, dotIndex + 2);
        }

        // หากมีทศนิยมมากกว่า 2 ตำแหน่ง ให้ตัดทศนิยมเกิน 2 ตำแหน่งออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).length > 2) {
            truncatedValue = truncatedValue.slice(0, dotIndex + 3);
        }

        // ตรวจสอบหากมีจุดทศนิยมซ้ำในค่าทศนิยม ให้ลบจุดทศนิยมที่เกิน 1 จุดออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).indexOf('.') !== -1) {
            const dotIndex2 = truncatedValue.slice(dotIndex + 1).indexOf('.') + dotIndex + 1;
            truncatedValue = truncatedValue.slice(0, dotIndex2);
        }
        setWeight(truncatedValue);
    };

    const handleHeightChange = (e) => {
        const value = e.target.value;

        // กรองเอาเฉพาะตัวเลขและทศนิยมเท่านั้น
        const filteredValue = value.replace(/[^0-9.]/g, "");

        // ตรวจสอบหากความยาวของค่าที่กรองแล้วมีความยาวเกิน maxLength ให้ตัดค่า
        let truncatedValue = filteredValue.slice(0, 9); // ปรับ maxLength เป็น 9 ตามตัวอย่าง

        // ตรวจสอบหากมีจุดทศนิยมในค่าที่ตัดแล้ว
        const dotIndex = truncatedValue.indexOf('.');

        // หากมีจุดทศนิยมอยู่หลายจุดในค่าที่ตัดแล้ว ให้ลบจุดทศนิยมที่เกิน 1 จุดออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).indexOf('.') !== -1) {
            truncatedValue = truncatedValue.slice(0, dotIndex + 2);
        }

        // หากมีทศนิยมมากกว่า 2 ตำแหน่ง ให้ตัดทศนิยมเกิน 2 ตำแหน่งออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).length > 2) {
            truncatedValue = truncatedValue.slice(0, dotIndex + 3);
        }

        // ตรวจสอบหากมีจุดทศนิยมซ้ำในค่าทศนิยม ให้ลบจุดทศนิยมที่เกิน 1 จุดออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).indexOf('.') !== -1) {
            const dotIndex2 = truncatedValue.slice(dotIndex + 1).indexOf('.') + dotIndex + 1;
            truncatedValue = truncatedValue.slice(0, dotIndex2);
        }
        setHeight(truncatedValue);
    };

    const handleWaistChange = (e) => {
        const value = e.target.value;

        // กรองเอาเฉพาะตัวเลขและทศนิยมเท่านั้น
        const filteredValue = value.replace(/[^0-9.]/g, "");

        // ตรวจสอบหากความยาวของค่าที่กรองแล้วมีความยาวเกิน maxLength ให้ตัดค่า
        let truncatedValue = filteredValue.slice(0, 9); // ปรับ maxLength เป็น 9 ตามตัวอย่าง

        // ตรวจสอบหากมีจุดทศนิยมในค่าที่ตัดแล้ว
        const dotIndex = truncatedValue.indexOf('.');

        // หากมีจุดทศนิยมอยู่หลายจุดในค่าที่ตัดแล้ว ให้ลบจุดทศนิยมที่เกิน 1 จุดออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).indexOf('.') !== -1) {
            truncatedValue = truncatedValue.slice(0, dotIndex + 2);
        }

        // หากมีทศนิยมมากกว่า 2 ตำแหน่ง ให้ตัดทศนิยมเกิน 2 ตำแหน่งออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).length > 2) {
            truncatedValue = truncatedValue.slice(0, dotIndex + 3);
        }

        // ตรวจสอบหากมีจุดทศนิยมซ้ำในค่าทศนิยม ให้ลบจุดทศนิยมที่เกิน 1 จุดออกไป
        if (dotIndex !== -1 && truncatedValue.slice(dotIndex + 1).indexOf('.') !== -1) {
            const dotIndex2 = truncatedValue.slice(dotIndex + 1).indexOf('.') + dotIndex + 1;
            truncatedValue = truncatedValue.slice(0, dotIndex2);
        }

        setWaist(truncatedValue);
    };

    const handleGenderChange = (e) => {
        setGender(e.target.value);
    };
    const handleAgeChange = (e) => {
        const value = e.target.value;

        const filteredValue = value.replace(/[^0-9.]/g, ""); // กรองเอาเฉพาะตัวเลขและทศนิยมเท่านั้น

        // ตรวจสอบหากความยาวของค่าที่กรองแล้วมีความยาวเกิน maxLength ให้ตัดค่า
        const truncatedValue = filteredValue.slice(0, 6);
        setAge(truncatedValue);
    };
    const PageAssesment = () => {

        if (cover_user.type == "member") {

            if (weight == "" || weight == null) {

                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุน้ำหนักของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });

            } else if (height == "" || height == null) {

                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุส่วนสูงของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });
            } else if (waist == "" || waist == null) {
                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุรอบเอวของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });

            }
            else {
                setLoading2(true)
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_API_URL_UAT}/assessment/customerBMI`,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${cover_user.token}`
                    }, data: {
                        "gender": gender,
                        "age": age,
                        "weight": weight,
                        "height": height,
                        "waistline": waist
                    }
                })
                    .then((res) => {
                        setLoading2(false)
                    })
                    .catch((err) => {
                        setLoading2(false)
                    });
                history.push("/assesment");
            }

        } else if (cover_user.type == "abuddy") {


            if (weight == "" || weight == null) {
                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุน้ำหนักของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });


            } else if (height == "" || height == null) {
                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุส่วนสูงของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });


            } else if (waist == "" || waist == null) {
                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุรอบเอวของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });

            } else if (gender == "" || gender == null) {
                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุเพศของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });


            } else if (age == "" || age == null) {
                Swal.fire({
                    html: `<div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                             <img src="/images/assesment/H1.png" alt="Avatar" style="width: 25%;">
                             <p style="white-space: pre-line;text-align: center;font-family: 'Sukhumvit Set tadmai bold';">
                             กรุณาระบุรอบอายุของท่าน
                             </p>
                           </div>`,
                    showCancelButton: false,
                    allowOutsideClick: false,
                    focusConfirm: false,
                    confirmButtonText: "ok",
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: "OK",

                    hideClass: {


                        confirmButton: 'swal2-noanimation'
                    }


                });



            }
            else {
                let roomidsestion = localStorage.getItem("roomid");
                const normalizedRoomid = roomidsestion.replace(/\"/g, "");


                setLoading2(true)
                axios({
                    method: "POST",
                    url: `${process.env.REACT_APP_API_URL_UAT}/assessment/customerBMI`,
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${cover_user.token}`
                    }, data: {
                        "gender": gender,
                        "age": age,
                        "weight": weight,
                        "height": height,
                        "waistline": waist
                    }
                })
                    .then((res) => {
                        setLoading2(false)
                    })
                    .catch((err) => {
                        setLoading2(false)
                    });
                history.push("/assesment?roomId=" + normalizedRoomid);
            }
        }




    }

    const handlers = useSwipeable({
        onSwipedLeft: () => {


            history.push("/assesment/insertw");


        },

        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });
    return (
        <div {...handlers} className="page-transition">
            <div>
                <header style={{ textAlign: 'center' }}>
                    <div style={{ position: 'relative' }}>
                        <img src="/images/assesment/BGPF.png" alt="รูปภาพ" style={{ maxWidth: '100%', maxHeight: '200px', width: '70vh', position: 'relative' }} />
                        <img src="/images/assesment/avartart.png" alt="รูปภาพ1" style={{ position: 'absolute', top: '90%', left: '50%', width: '14vh', transform: 'translate(-50%, -50%)', zIndex: 1 }} />
                    </div>
                    <div style={{ position: 'absolute', top: '45px', left: '50%', transform: 'translate(-50%, -50%)', lineHeight: '25px' }}>
                        <span style={{ fontSize: '22px', fontWeight: 'bold', fontFamily: 'Sukhumvit Set Extra Bold' }}>ข้อมูลส่วนตัว</span><br />
                    </div>
                </header>
                <div className="page overflow_disable" style={{ minHeight: '90vh' }}>
                    <div className="containerAssesment" style={{ marginTop: '70px' }}>
                        <div className="card" style={{ borderRadius: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)', borderTop: '0px solid', paddingLeft: '20px', paddingTop: '20px' }}>
                            <div className="card-body">
                                <form >
                                    {cover_user.type == "abuddy" ? <div className="row">
                                        <div className="col">
                                            <div className="mb-3">
                                                <img src="/images/assesment/date.png" alt="รูปภาพ1" style={{ width: '31px' }} />
                                                <label htmlFor="ageInput" className="form-label lablefont" style={{ marginLeft: '16px', fontFamily: 'Sukhumvit Set tadmai bold' }} >อายุ</label>
                                                <div className="divpadding2" style={{ paddingLeft: '36px' }}>
                                                    <input maxLength={3}
                                                        inputMode="decimal"
                                                        step="any"
                                                        className="form-control" id="ageInput" value={age} onChange={handleAgeChange} style={{
                                                            backgroundColor: '#F2F7ED',

                                                            borderTop: '1px solid rgba(0, 0, 0, 0.1)', // เพิ่ม border โปร่งแสงและไม่เข้มมาก
                                                            boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
                                                            height: "40px"
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="mb-3">
                                                <label htmlFor="genderSelect" className="form-label lablefont">เพศ</label><br></br>
                                                <select className="form-select selecygender" id="genderSelect" style={{ textAlign: 'center', marginTop: '14px' }} value={gender} onChange={handleGenderChange}  >
                                                    <option value="">เลือก</option>
                                                    <option value="male">ชาย</option>
                                                    <option value="female">หญิง</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                        : null
                                    }




                                    <div className="mb-3">
                                        <img src="/images/assesment/scal.png" alt="รูปภาพ1" className="imgicon" />
                                        <label htmlFor="weightInput" className="form-label lablefont" style={{ marginLeft: '-7px', fontFamily: 'Sukhumvit Set tadmai bold' }}>น้ำหนัก</label>
                                        <div className="divpadding3">

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input maxLength={6}
                                                    inputMode="decimal"
                                                    step="any"
                                                    // InputProps={{
                                                    //     inputComponent: NumberFormatCustomDicimal,
                                                    // }}

                                                    className="form-control" id="weightInput" value={weight} onChange={handleWeightChange} style={{
                                                        backgroundColor: '#F2F7ED',
                                                        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                                        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)', // ปรับแก้ค่าใน boxShadow เพื่อให้เงาแสดงด้านล่าง
                                                        height: '40px',
                                                    }} />
                                                <span className="spandis">กก.</span>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <img src="/images/assesment/ruler.png" style={{ width: '20px', marginRight: '30px' }} alt="รูปภาพ1" className="imgicon" />
                                        <label htmlFor="weightInput" className="form-label lablefont" style={{ marginLeft: '-7px', fontFamily: 'Sukhumvit Set tadmai bold' }}>ส่วนสูง</label>
                                        <div className="divpadding3">

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input maxLength={6}

                                                    inputMode="decimal"
                                                    step="any"
                                                    className="form-control"
                                                    id="waistInput"
                                                    value={height}
                                                    onChange={handleHeightChange}
                                                    style={{
                                                        backgroundColor: '#F2F7ED',
                                                        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                                        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
                                                        height: '40px',
                                                        // เพิ่มระยะห่างระหว่าง input กับ "cm"
                                                    }}
                                                />
                                                <span className="spandis">ซม.</span>
                                            </div>


                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <img src="/images/assesment/temp.png" style={{ width: '32px', marginRight: '18px' }} alt="รูปภาพ1" className="imgicon" />
                                        <label htmlFor="weightInput" className="form-label lablefont" style={{ marginLeft: '-7px', fontFamily: 'Sukhumvit Set tadmai bold' }}>รอบเอว</label>
                                        <div className="divpadding3">

                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <input
                                                    inputMode="decimal"
                                                    step="any"
                                                    className="form-control"
                                                    id="waistInput"
                                                    value={waist}
                                                    maxLength={5}
                                                    onChange={handleWaistChange}
                                                    style={{
                                                        backgroundColor: '#F2F7ED',
                                                        borderTop: '1px solid rgba(0, 0, 0, 0.1)',
                                                        boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
                                                        height: '40px',


                                                    }}
                                                />
                                                <span className="spandis">นิ้ว&nbsp;</span>
                                            </div>


                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div >
                            <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                                <button style={{ borderRadius: '25px', backgroundColor: 'rgb(15, 119, 74)', width: '40vh', marginTop: '5vh', fontFamily: 'Sukhumvit Set tadmai bold', borderColor: 'rgb(15, 119, 74)', fontSize: '20px' }} type="button" className="btn btn-primary btn-lg btn-block button" onClick={() => PageAssesment()}>Next</button>
                            </div>
                        </div>
                    </div>
                </div>
                <LoadingOverlay active={loading2} spinner text="Loading" styles={{ wrapper: { width: '100%', minHeight: '100%' } }} />
            </div>
        </div>
    );
};

export default InsertW;
