import config from "../../config";
import { ChallengeVideo } from "../../model/ChallengeVideo.model";
import { sortIntByKey } from "../../utill.func";
import AxiosHealthWellness from "../healthWellness";

export const getChallengeVideos = async (params) => {

    try {
        const response = await AxiosHealthWellness.get(
            `${config.API_URL}/hnw/challenge-external/video`,
            {
                params
            });

        const filteredVdoData = response?.data.filter(video => video.link && video.link.trim() !== '');
        const data = sortIntByKey(filteredVdoData, 'day');

        return data?.map(video => new ChallengeVideo(video));
    }
    catch (error) {
        throw error;
    }
}

export const getYoutubeVideoDetails = async (videoIds) => {
    try {
        const response = await AxiosHealthWellness.get(
            `${config.API_URL}/hnw/challenge-external/challenge-videos-details`, {
            params: {
                part: 'snippet,contentDetails',
                ids: videoIds.join(','),
            }
        });

        return response?.data?.items;

    } catch (error) {
        throw error;
    }
};

export const updateChallengeVideo = async (data) => {
    try {

        const response = await AxiosHealthWellness.patch(
            `${config.API_URL}/hnw/challenge-external/video/update-status`, data
        );
        return response?.data;
    }
    catch (error) {
        throw error;
    }
}