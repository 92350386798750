import config from '../../config';
import {
  ChallengeBodyKeyErrorStatus,
  ChallengeBodyKeyOtpErrorResponse,
  ChallengeBodyKeyOtpResponse,
  ChallengeBodyKeyRecord,
  ChallengeBodyKeyStatus,
} from '../../model/ChallengeBodyKey.model';
import AxiosInterceptor from '../axiosInterceptor.service';
import AxiosHealthWellness from '../healthWellness';

export const verifyBodyKeyId = async (bodyKeyId) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/hnw/challenge-external/body-data/app-settings?bodyKeyId=${bodyKeyId}`,
    );
    if (response?.data?.IsSuccess) {
      return new ChallengeBodyKeyStatus(response?.data);
    } else {
      throw {
        response: {
          data: {
            error: response?.data?.ErrorMsg || '',
            message: response?.data?.ErrorMsg || '',
            path: response?.data?.path || '',
            statusCode: response?.data?.statusCode || 400,
            timestamp: response?.data?.timestamp || '',
          },
        },
      };
    }
  } catch (error) {
    throw new ChallengeBodyKeyErrorStatus(error.response.data);
  }
};

export const getBodyKeyRecord = async ({ startDate, endDate, bodyKeyId }) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/hnw/challenge-external/body-data/exercise?startDate=${startDate}&endDate=${endDate}&bodyKeyId=${bodyKeyId}`,
    );
    const bodyKeyRecord = new ChallengeBodyKeyRecord(response?.data);
    sessionStorage.setItem(
      'stepCount',
      JSON.stringify(bodyKeyRecord?.data[0]?.stepCount || 0),
    );
    return bodyKeyRecord;
  } catch (error) {
    throw error;
  }
};

export const generateOtpBodyKey = async (amwayNumber) => {
  try {
    const response = await AxiosInterceptor.post(
      `${config.API_URL}/otp/getOTP/${amwayNumber}`,
      null,
    );
    return new ChallengeBodyKeyOtpResponse(response?.data);
  } catch (error) {
    throw error;
  }
};

export const verifyOtpBodyKey = async ({ otp, accountId }) => {
  try {
    const response = await AxiosInterceptor.post(
      `${config.API_URL}/otp/verifyOTPWithoutUpdate`,
      { otp, accountId },
      { headers: { 'Content-Type': 'application/json' } },
    );
    return {
      statusCode: response.data?.code,
    };
  } catch (error) {
    throw new ChallengeBodyKeyOtpErrorResponse(error.response.data);
  }
};
