import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { commonConstant } from '../../../common';
import {
  addProductsInstallmentToCart,
  addProductsToCart,
  addProductsToCartPublic,
  createCartInstallment,
  createCartPublic,
  getCart,
  getCartPublic,
  getPriceFormat,
  getPromotionById,
  getPublicPromotionById,
  isCartABOMemberType,
  isCartABOType,
  message,
  PAYMENT_OPTION,
} from '../../../services/promotion/utils.service';
import Liff from '../../modal/Liff';
import '../../promotion/CartPromotion.scss';
import CartIcon from '../CartIcon';
import PaymentOption from '../PaymentOption';
import BundleProductItem from '../PromotionTemplate/BundleProductItem';
import Product from '../PromotionTemplate/Product';
import { ProductItemType } from '../PromotionTemplate/Product';
import { liffGetProfile } from '../../../services/promotion/auth.service';
import { getCartData, updateCartData } from '../../../actions/cartAction';
import { connect } from 'react-redux';

export const history = createBrowserHistory();

export const ABO_MEMBER = 'ABO';
export const FOA_MEMBER = 'RETAIL';

export const UpdateState = {
  FINISH: 'FINISH',
  CHANGE: 'CHANGE',
  CLEAR: 'CLEAR',
  INIT: 'INIT',
};

const { liff } = window;

export class PromotionProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cart: null,
      isFOAEmpty: '',
      entries: 0,
      accountType: '',
      totalPrice: 0,
      totalPointValue: 0,
      totalBusinessVolume: 0,
      loading: true,
      promotionData: [],
      totalSelected: 0,
      isLiffopen: false,
      paymentOptions: [],
      relatedProducts: [],
      productSelected: [],
      paymentOptionSelected: null,
    };

    this.onOpenLiff = this.onOpenLiff.bind(this);
    this.onCloseLiff = this.onCloseLiff.bind(this);
    this.setTotalprice = this.setTotalprice.bind(this);
    this.setTotalSelected = this.setTotalSelected.bind(this);
    this.handleFullPayment = this.handleFullPayment.bind(this);
    this.setProductSelected = this.setProductSelected.bind(this);
    this.handleProductToCart = this.handleProductToCart.bind(this);
    this.handlePayByInstallment = this.handlePayByInstallment.bind(this);
    this.setPaymentOptionSelected = this.setPaymentOptionSelected.bind(this);
  }

  async componentDidMount() {
    await this.fetchData();
  }

  async fetchData() {
    const urlParams = queryString.parse(history.location.search);
    const promotionId = urlParams.id;

    if (!promotionId) {
      return alert('ไม่พบข้อมูลโปรโมชั่น');
    }

    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));
    const guid = sessionStorage.getItem('guid');
    if(!customerFOA){
      this.setState({ isFOAEmpty : true });
    }
    const cart = !customerFOA
      ? (this.props?.data.length > 0 ? this.props.data : await this.props.getCartData('FULL'))
      : guid
      ? await getCartPublic(guid)
      : await createCartPublic();
    if (Array.isArray(cart)) {
      const promotionData = !customerFOA
        ? await getPromotionById(promotionId)
        : await getPublicPromotionById(promotionId);
      const relatedProducts = await this.setQuantityToProduct(
        promotionData.relatedProducts,
      );
      this.setState({
        cart,
        promotionData,
        relatedProducts,
        loading: false,
      });
    } else {
      console.log('CART : ', cart);
      alert('ไม่พบข้อมูลตระกร้า กรุณาลองใหม่ภายหลัง');
    }
    this.setAccountType(cart[0].account.accountType);
    let totalEntries = 0;
    let entries = cart[0].entries.map((values) => {
      return values.quantity;
    });
    entries.forEach((element) => {
      totalEntries += element;
    });
    this.setCartEntries(totalEntries);
  }

  onCloseLiff() {
    this.setState({ isLiffopen: false });
  }

  onOpenLiff() {
    this.setState({ isLiffopen: true });
  }

  setPaymentOptionSelected(option) {
    this.setState({ paymentOptionSelected: option });
  }

  setQuantityToProduct(relatedProducts) {
    const newRelatedProducts = relatedProducts.map((product) => {
      product.quantity = 0;
      if (product.kitEntries.length > 0) {
        product.kitEntries.map((kitEntry) => {
          const configuredQty = kitEntry.configuredQty;
          if (kitEntry.product.baseOptions.length > 0) {
            const baseOptions = kitEntry.product.baseOptions[0];
            baseOptions.options.map((option, index) => {
              if (kitEntry.product.baseProduct) {
                if (baseOptions.selectedId) {
                  const quantity =
                    baseOptions.selectedId === option.id ? configuredQty : 0;
                  option.quantity = quantity;
                  option.lastQuantity = quantity;
                }
              } else {
                option.quantity = index === 0 ? configuredQty : 0;
                option.lastQuantity = index === 0 ? configuredQty : 0;
              }
            });
          }
        });
      }
      return product;
    });
    return newRelatedProducts;
  }

  setTotalprice(totalPrice) {
    return this.setState({ totalPrice });
  }

  setCartEntries = (entries) => {
    return this.setState({ entries });
  };

  setAccountType = (accountType) => {
    return this.setState({ accountType });
  };

  setTotalPointValue = (totalPointValue) => {
    return this.setState({ totalPointValue });
  };

  setTotalBusinessVolume = (totalBusinessVolume) => {
    return this.setState({ totalBusinessVolume });
  };

  setTotalSelected(totalSelected) {
    return this.setState({ totalSelected });
  }

  setProductSelected(productId, quantity, updateState, rootProductId) {
    const relatedProducts = this.state.relatedProducts.map((product) => {
      if (product.id === productId) {
        product.quantity = quantity;
      }
      if (product.kitEntries.length > 0) {
        product.kitEntries.map((kitEntry) => {
          if (kitEntry.product.baseOptions.length > 0) {
            const baseOptions = kitEntry.product.baseOptions[0];
            baseOptions.options.map((option) => {
              if (kitEntry.kitEntryCode.indexOf(rootProductId) >= 0) {
                if (updateState === UpdateState.FINISH) {
                  option.lastQuantity = option.quantity;
                } else if (option.id === productId) {
                  if (updateState === UpdateState.CLEAR) {
                    option.quantity = option.lastQuantity;
                  }
                  if (updateState === UpdateState.CHANGE) {
                    option.quantity = quantity;
                  }
                }
              }
            });
          }
        });
      }
      return product;
    });
    this.setState({ relatedProducts });
  }

  async handleProductToCart() {
    if (this.state.totalPrice === 0) {
      return;
    }

    const isHavePayByInstallment =
      this.state.promotionData.paymentOptions.length !== 0;
    if (isHavePayByInstallment) {
      this.handleFilterPaymentOptions();
      return;
    }

    this.handleFullPayment();
  }

  handleFilterPaymentOptions() {
    const selectedProducts = this.state.relatedProducts.filter(
      (product) => product.quantity !== 0,
    );
    let productPaymentOption = [];
    const allPaymentMethods = [];
    for (const product of selectedProducts) {
      if (product.paymentOptions.length !== 0) {
        for (const paymentOption of product.paymentOptions) {
          allPaymentMethods.push(paymentOption.title.trim());
          productPaymentOption.push(paymentOption);
        }
      }
    }

    const counts = {};
    allPaymentMethods.forEach(function (x) {
      counts[x] = (counts[x] || 0) + 1;
    });
    const paymentMethods = Object.keys(counts).filter(
      (key) => counts[key] === selectedProducts.length,
    );
    productPaymentOption = paymentMethods.map((paymentMethod) =>
      productPaymentOption.find((value) => {
        if (value.title.trim() === paymentMethod.trim()) {
          return value;
        }
      }),
    );

    this.setState({ paymentOptions: productPaymentOption.reverse() });
    this.onOpenLiff();
    return;
  }

  async handleFullPayment() {
    this.setState({ loading: true });
    const cartId = this.state.cart[0].id;
    const customerFOA = JSON.parse(sessionStorage.getItem('customerFOAData'));

    const response = !customerFOA
      ? await addProductsToCart(cartId, this.state.relatedProducts)
      : await addProductsToCartPublic(
          cartId,
          this.state.relatedProducts,
          this.state.cart[0].guid,
        );

    sessionStorage.setItem('guid', this.state.cart[0].guid);
    this.setState({ loading: false });

    if (response.error) {
      return alert(response.error[0].th_message);
    }
    //Update latest cartData to redux
    await this.props.updateCartData([response.data]);
    this.goToCart();
  }

  async handlePayByInstallment() {
    try {
      this.setState({ loading: true, isLiffopen: false });
      if (
        this.state.paymentOptionSelected.paymentType.key === PAYMENT_OPTION.PIF
      ) {
        await this.handleFullPayment();
        return;
      }
      const { userId } = await liffGetProfile();
      const cart = await createCartInstallment(userId);

      const cartId = cart.id;
      const response = await addProductsInstallmentToCart(
        cartId,
        this.state.relatedProducts,
        this.state.paymentOptionSelected,
      );
      if (response.error) {
        alert(response.error[0].th_message);
        this.setState({ loading: false });
        return;
      }
      this.setState({ loading: false });
      return (window.location.href = `${commonConstant.pathCartPromotion}?isInstallment=TRUE&cartId=${cartId}`);
    } catch (error) {
      alert(message.error.somethingWrong);
      this.setState({ loading: false });
    }
  }

  goToCart() {
    this.props.history.push(commonConstant.pathCartPromotion);
  }

  renderProduct(relatedProducts) {
    if (relatedProducts.length === 0) {
      return <></>;
    }
    return relatedProducts.map((product, index) => {
      const img =
        product.galleryImages.length > 0
          ? product.galleryImages[0].image_url
          : '';
      let customerFOA = sessionStorage.getItem('customerFOAData');
      let aboPrice = product.prices.find((value) => value.type === ABO_MEMBER);
      let foaPrice = product.prices.find((value) => value.type === FOA_MEMBER);
      foaPrice = parseInt(foaPrice.price);
      aboPrice = parseInt(aboPrice.price);

      const { accountType = '' } = this.state.cart[0]?.account;
      const isABOMemberType = isCartABOMemberType(accountType);
      const isABOType = isCartABOType(accountType);
      const isBundletItem = product.kitEntries.length > 0;

      if (isBundletItem) {
        const props = {
          img: img,
          key: index,
          id: product.id,
          price: isABOMemberType ? aboPrice : foaPrice,
          code: product.sku,
          stock: product.stock,
          title: product.identifier,
          quantity: product.quantity,
          amwayValue: isABOType ? product.amwayValue : null,
          kitEntries: product.kitEntries,
          totalPrice: this.state.totalPrice,
          setTotalPrice: this.setTotalprice,
          totalPointValue: this.state.totalPointValue,
          setTotalPointValue: this.setTotalPointValue,
          totalBusinessVolume: this.state.totalBusinessVolume,
          setTotalBusinessVolume: this.setTotalBusinessVolume,
          productItemType: ProductItemType.SELECT,
          totalSelected: this.state.totalSelected,
          setTotalSelected: this.setTotalSelected,
          setProductSelected: this.setProductSelected,
        };
        return <BundleProductItem {...props} />;
      }

      const props = {
        img: img,
        key: index,
        id: product.id,
        price: isABOMemberType ? aboPrice : foaPrice,
        code: product.sku,
        stock: product.stock,
        title: product.identifier,
        amwayValue: isABOType ? product.amwayValue : null,
        totalPrice: this.state.totalPrice,
        setTotalPrice: this.setTotalprice,
        totalPointValue: this.state.totalPointValue,
        setTotalPointValue: this.setTotalPointValue,
        totalBusinessVolume: this.state.totalBusinessVolume,
        setTotalBusinessVolume: this.setTotalBusinessVolume,
        totalSelected: this.state.totalSelected,
        productItemType: ProductItemType.SELECT,
        setTotalSelected: this.setTotalSelected,
        setProductSelected: this.setProductSelected,
        isFlashSale: this.state.promotionData.isFlashSale,
      };
      return <Product {...props} />;
    });
  }

  handleDisplayPromotion(promotionData) {
    return this.renderProduct(promotionData);
  }

  renderHeader() {
    const promotion = this.state.promotionData;
    const isCartABOMember = isCartABOMemberType(this.state.accountType);
    //this is the only key for getting must have items
    const isMustHavePromotion =
      isCartABOMember &&
      promotion &&
      promotion.name &&
      promotion.name.toLowerCase() === 'must have items';
    const isRecommenderProductPrm =
      !isCartABOMember && promotion && promotion.name ? true : false;
    const blueHeaderSrc = '/images/sop/sop_profile_list_header.png';
    const orangeHeaderSrc = '/images/bg-header-promotion.png';
    const imgSrc =
      isMustHavePromotion || isRecommenderProductPrm
        ? blueHeaderSrc
        : orangeHeaderSrc;
    return (
      <>
        <img src={imgSrc} className="w-100" />
      </>
    );
  }

  render() {
    const { isFOAEmpty, loading } = this.state;
    const disabledStyle = this.state.totalPrice === 0 ? 'disabled' : '';
    return (
      <section>
        <LoadingOverlay
          text="Loading"
          spinner
          active={loading}
          styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
        />
        <div className="promotion-container scroll">
          <div className="promotion-header-container">
            {this.renderHeader()}
            <div className="promotion-header flex">
              <div className="promotion-content">
                <div className="promotion-title">{'สินค้าในโปรโมชัน'}</div>
                <div className="promotion-description">
                  {this.state.promotionData.name}
                </div>
              </div>
              {loading ? <p>Loading...</p> : <CartIcon isFOAEmpty={isFOAEmpty} />}
            </div>
          </div>
          {isCartABOMemberType(this.state.accountType) &&
            this.state.promotionData?.shortDescription && (
              <div className="description-box-container">
                <div className="short-description-box">
                  {this.state.promotionData.shortDescription}
                </div>
              </div>
            )}
          <div className="promotion-product-panel">
            <div className="mb-10">
              {this.handleDisplayPromotion(this.state.relatedProducts)}
            </div>
          </div>
        </div>
        <footer>
          <div className="product-panel-footer">
            <div className="select-product-footer font-larger">
              <div className="price-pvbv-container">
                <div className="product-panel-footer-price">
                  <span>฿ {getPriceFormat(this.state.totalPrice)}</span>
                </div>
                {isCartABOType(this.state.accountType) && (
                  <div className="pvbv-total">
                    PV {this.state.totalPointValue.toLocaleString()} / BV{' '}
                    {this.state.totalBusinessVolume.toLocaleString()}
                  </div>
                )}
              </div>
              {this.state.totalPrice == 0 ? (
                <span className="add-to-cart-disabled" disabled>
                  <img
                    src="/images/promotion/icon_cart.png"
                    style={{ marginRight: '5px' }}
                  />
                  <span className="add-to-cart-text-disabled">ใส่ตะกร้า</span>
                </span>
              ) : (
                <span
                  className="add-to-cart-active"
                  onClick={this.handleProductToCart}
                >
                  <img
                    src="/images/promotion/icon_cart_active.png"
                    style={{ paddingRight: '6px', paddingBottom: '4px' }}
                  />
                  <span className="add-to-cart-text-active">
                    ใส่ตะกร้า
                  </span>
                </span>
              )}
            </div>
          </div>
        </footer>
        <Liff
          title="เลือกวิธีการชำระที่ต้องการ"
          onClose={this.onCloseLiff}
          isOpen={this.state.isLiffopen}
        >
          <div
            className="border-panel"
            style={{ height: '70vh', marginBottom: 10 }}
          >
            <PaymentOption
              paymentOptions={this.state.paymentOptions}
              setPaymentOptionSelected={this.setPaymentOptionSelected}
            />
          </div>
          <button
            className="btn-bottom"
            onClick={this.handlePayByInstallment}
            disabled={!this.state.paymentOptionSelected}
          >
            ตกลง
          </button>
        </Liff>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.cartApi.data,
    loading: state.cartApi.loading,
    error: state.cartApi.error,
  };
};

const mapDispatchToProps = (dispatch)=> {
  return {
    getCartData: (fields) => dispatch(getCartData(fields)),
    updateCartData: (data) => dispatch(updateCartData(data)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionProduct);
