import { ADDR_FETCH, POLICY_FETCH, CHECK_RENEWAL, ADD_ADDRESS, CREATE_CART, ADD_ITEM, STORE_FETCH, VALIDATE_CART, CAL_CART, PICK_STORE, UPDATE_DELIVERY } from './types';
import axios from 'axios';

export const policyFetch = ({ accountSubTypeCd }) => dispatch => {
  axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}/renewal/policies`,
    data: {
      contractType: 'Renewals',
      accountSubTypeCd: accountSubTypeCd || 'BusinessOwner',
      contractID: '180003',
    },
  }).then(res => {
    dispatch({
      type: POLICY_FETCH,
      payload: res.data,
    });
  });
};

export const setAddress = params => dispatch => {
  dispatch({
    type: ADDR_FETCH,
    payload: params,
  });
};

export const addrFetch = (uid) => dispatch => {
  axios.get(`${process.env.REACT_APP_API_URL}/renewal/user/${uid}/address`).then(res => {
    dispatch({
      type: ADDR_FETCH,
      payload: res.data,
    });
  });
};

export const isAlreadyRenewal = (uid) => dispatch => {
  axios.get(`${process.env.REACT_APP_API_URL}/renewal/isAlreadyRenewal/${uid}`).then(res => {
    dispatch({
      type: CHECK_RENEWAL,
      payload: res.data,
    });
  });
};

export const addNewAddress = params => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/address`, params).then(res => {
    dispatch({
      type: ADD_ADDRESS,
      payload: res.data,
    })
  }).catch(err => {
    dispatch({
      type: ADD_ADDRESS,
      payload: err.response.data,
    });
  });
};

export const setAddNewAddress = params => dispatch => {
  dispatch({
    type: ADD_ADDRESS,
    payload: params,
  });
};

export const createCart = params => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/carts`, params).then(res => {
    dispatch({
      type: CREATE_CART,
      payload: res.data,
    })
  }).catch(err => {
    dispatch({
      type: CREATE_CART,
      payload: err.response.data,
    })
  })
}

export const addRenewalEntry = params => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/renewalCartEntries`, params).then(res => {
    dispatch({
      type: ADD_ITEM,
      payload: res.data,
    })
  }).catch(err => {
    dispatch({
      type: ADD_ITEM,
      payload: err.response.data,
    })
  })
}

export const getPickup = () => dispatch => {
  axios.get(`${process.env.REACT_APP_API_URL}/renewal/pickupstores`,{
    headers: {
      'Authorization': 'Bearer dummy'
    }
  }).then(res => {
    dispatch({
      type: STORE_FETCH,
      payload: res.data,
    })
  })
}

export const choosePickupStore = ({ cartCode, partyId, storeId, currStore }) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/choosePickupStore`, { cartCode, partyId, storeId }).then(res => {
    dispatch({
      type: PICK_STORE,
      payload: {
        ...res.data,
        data: {
          entry: {
            ...currStore,
            deliveryPointOfService: {
              address: currStore.address,
              displayName: currStore.displayName
            }
          }
        }
      },
    })
  }).catch(err => {
    dispatch({
      type: CREATE_CART,
      payload: err.response.data,
    })
  })
}

export const updateDelivery = ({ cartCode, partyId, pickup }) => dispatch => {
  axios.post(`${process.env.REACT_APP_API_URL}/renewal/updateDelivery`, { cartCode, partyId, pickup }).then(res => {
    dispatch({
      type: UPDATE_DELIVERY,
      payload: res.data,
    })
  }).catch(err => {
    dispatch({
      type: CREATE_CART,
      payload: err.response.data,
    })
  })
}

export const validateCart = (partyId, cartId) => dispatch => {
  axios.put(`${process.env.REACT_APP_API_URL}/renewal/validateCart/${partyId}/cartId/${cartId}`).then(res => {
    dispatch({
      type: VALIDATE_CART,
      payload: res.data,
    })
  }).catch(err => {
    dispatch({
      type: VALIDATE_CART,
      payload: err.response.data,
    });
  });
}

export const calculateCart = (partyId, cartId) => dispatch => {
  axios.get(`${process.env.REACT_APP_API_URL}/renewal/calculateCart/${partyId}/cartId/${cartId}`).then(res => {
    dispatch({
      type: CAL_CART,
      payload: res.data,
      calculated: true
    })
  }).catch(err => {
    dispatch({
      type: CAL_CART,
      payload: err.response.data,
      calculated: false
    })
  });
}