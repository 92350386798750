import config from '../../config';
import {
  ChallengeImageData,
  ImagePreSignedUrlAndPath,
} from '../../model/ChallengeImageUpload.model';
import AxiosHealthWellness from '../healthWellness';

export const getChallengeImages = async ({ eventId, userId, scoreId }) => {
  try {
    let URL = `${config.API_URL}/hnw/challenge-external/challenge-image?eventId=${eventId}&userId=${userId}`;
    if (scoreId) {
      URL = `${URL}&scoreId=${scoreId}`;
    }
    const response = await AxiosHealthWellness.get(URL);
    return response?.data?.length
      ? response?.data?.map((imageObj) => new ChallengeImageData(imageObj))
      : [];
  } catch (error) {
    throw error;
  }
};

export const getPreSignedUrl = async ({
  eventId,
  partyId,
  fileName,
  contentType,
}) => {
  try {
    let URL = `${config.API_URL}/hnw/challenge-external/challenge-image/pre-signed-url?eventId=${eventId}&partyId=${partyId}&fileName=${fileName}&contentType=${contentType}`;
    const response = await AxiosHealthWellness.get(URL);
    return new ImagePreSignedUrlAndPath(response?.data);
  } catch (error) {
    throw error;
  }
};

export const putPreSignedUrl = async ({ preSignedUrl, contentType, file }) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", contentType);
    const requestOptions = {
      method: "PUT",
      redirect: "follow",
      headers: myHeaders,
      body: file,
    };
    const response = await fetch(preSignedUrl, requestOptions)
    const result = response.text();
    return result;
  } catch (error) {
    throw error;
  }
};

export const putConfirmUpload = async ({
  eventId,
  uploadId,
  path,
  approveStatus,
  fileDesc,
  uploadDate,
  createdAt,
}) => {
  try {
    const data = `eventId=${eventId}&uploadId=${uploadId}&path=${path}&approveStatus=${approveStatus}&fileDesc=${fileDesc}&uploadDate=${uploadDate}&createdAt=${createdAt}`;
    const configObj = {
      maxBodyLength: Infinity,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    const response = await AxiosHealthWellness.put(
      `${config.API_URL}/hnw/challenge-external/challenge-image/confirm-upload`,
      data,
      configObj,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
