import config from '../../config';
import {
  ChallengeTeamDashboard,
  UserDetailModel,
  BadgeDetailCountModel,
} from '../../model/ChallengeTeamDashboard.model';
import AxiosInterceptor from '../axiosInterceptor.service';
import { getDataFromSession } from '../../utill.func';
import AxiosHealthWellness from '../healthWellness';
export const fetchTeamDashboardDetail = async ({
  amwayNumber,
  partyId,
  challengeId,
}) => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/hnw/challenge-external/team-full-data?eventId=${challengeId}&aboId=${amwayNumber}&partyId=${partyId}`,
    );
    const challengeData = getDataFromSession('challengeData');
    return new ChallengeTeamDashboard({
      ...response?.data,
      partyId,
      challengeData
    });
  } catch (error) {
    throw error;
  }
};

export const fetchUserDetail = async (partyIds) => {
  try {
    const response = await AxiosInterceptor.post(
      `${config.API_URL}/customers/party-line-detail`,
      { partyIds: partyIds },
    );

    return new UserDetailModel(response?.data);
  } catch (error) {
    throw error;
  }
};

export const fetchViewedBadgeDetail = async () => {
  try {
    const response = await AxiosHealthWellness.get(
      `${config.API_URL}/hnw/challenge/badge-count`
    );

    return new BadgeDetailCountModel(response?.data);
  } catch (error) {
    throw error;
  }
};

export const updateViewBadgeCountData = async (badgeViewedCount) => {
  try {
    const response = await AxiosHealthWellness.post(
      `${config.API_URL}/hnw/challenge/badge-count`,
      { badgeViewedCount }
    );

    return new BadgeDetailCountModel(response?.data);
  } catch (error) {
    throw error;
  }
};
