import React, { Component } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import '../../sop/Subscription/SOPSubscription.scss';
import { getSOPDetail } from '../../../services/sop-sub.service';
import PDPItem from '../SOPTemplate/PDPItem';
import { createBrowserHistory } from 'history';
import queryString from 'query-string';
import { getCart } from '../../../services/promotion/utils.service';
import { connect } from 'react-redux';
import { getCartData } from '../../../actions/cartAction';

export const history = createBrowserHistory();

export class PDP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      cartData: {},
      id: props.location.state?.id?props.location.state?.id:null,
    };
  }

  async componentDidMount() {
    if (this.props?.cart && this.props?.cart.length === 0) {
      await this.props.getCartData('FULL');
    }    
    this.fetchData();
  }

  async fetchData() {
    let urlParams = {};
    urlParams['id'] = this.state.id;
    if (!urlParams) {
      urlParams = queryString.parse(history.location.search);
    }
    const sopId = urlParams.id;

    let cart = this.props.cart;
    if(!cart){
       cart = await this.props.getCartData("Full");
      this.setState({ cartData: cart[0] });
    }else{
      this.setState({ cartData: cart[0] });
    }

    const detail = await getSOPDetail(sopId, cart[0]?.id);
    this.setState({ data: detail, loading: false });
  }

  displayDetail() {
    const info = this.state.data ? this.state.data : null
    if (info.data?.id) {
      const {
        id,
        identifier,
        summary,
        galleryImages,
        prices,
        amwayValue,
        sopPlans,
        kitEntries,
      } = info.data;
      const img = galleryImages.length > 0 ? galleryImages[0].image_url : '';
      let aboPrice = prices.find((value) => value.type === 'ABO');
      aboPrice = parseInt(aboPrice.price);
      return (
        <PDPItem
          info={info}
          id={id}
          img={img}
          identifier={identifier}
          desc={summary}
          price={aboPrice}
          amwayValue={amwayValue}
          sopPlans={sopPlans}
          isKitEntries={kitEntries}
        />
      );
    }
  }

  render() {
    return (
      <section>
        <LoadingOverlay
          text="Loading"
          spinner
          active={this.state.loading}
          styles={{ wrapper: { width: '100%', minHeight: '100%' } }}
        />
        {this.displayDetail()}
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cartApi.data,
    loading: state.cartApi.loading,
    error: state.cartApi.error,
  };
};

const mapDispatchToProps = (dispatch)=> {
  return {
    getCartData: (fields) => dispatch(getCartData(fields)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PDP);
