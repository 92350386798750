import config from '../../config';
import { ChallengeQuiz, QuizUserAnswers } from "../../model/ChallengeQuiz.model";
import AxiosHealthWellness from '../healthWellness';
import {VDO_SORTED_IDS} from './../../common/challengeConstants'

export const getChallengeQuiz = async (params) => {
    
    try {
        const response = await AxiosHealthWellness.get(
            `${config.API_URL}/hnw/challenge-external/quiz`,
            {
                params
            });
        const quizData = response?.data;
        const sortedExamParts = Array.isArray(quizData) ? quizData?.reduce((sorted, part) => {
            const index = VDO_SORTED_IDS.indexOf(part?.exam_part_id);
            if (index !== -1) {
              sorted.splice(index, 0, part);
            } else {
              sorted.push(part);
            }
            return sorted;
          }, []) : [];
          return sortedExamParts?.map(round => new ChallengeQuiz(round));
          
    } catch (error) {
        throw error;
    }
};

export const updateQuizUserAnswers = async (examUserId, answers, isSendToChallenge) => {
    try {
        const response = await AxiosHealthWellness.put(`${config.API_URL}/hnw/challenge-external/quiz/answer`,
            {
                examUserId,
                answers,
                isSendToChallenge
            },
        );
        return response?.data?.request_id;
    } catch (error) {
        throw error;
    }
};

export const getUpdatedAnswers = async (requestId) => {

    try {
        const response = await AxiosHealthWellness.get(
            `${config.API_URL}/hnw/challenge-external/quiz/answer?requestId=${requestId}`);
        const isError = response?.data?.is_error;
        return isError ? response?.data : new QuizUserAnswers(response?.data?.processed_result);
    } catch (error) {
        throw error;
    }
};