import config from '../config';
import AxiosPromotion from '../services/promotion';
import {  CART_REQUEST, CART_SUCCESS, CART_FAILURE, CREATE_CART_SUCCESS } from './types';

export const getCartData = (fields) => {
    return  async (dispatch) => { 
      dispatch({ type: CART_REQUEST });
      try{          
        let response = AxiosPromotion.get(
            `${config.API_URL}/promotion/cart`,
            {
                params: {
                    fields,
                },
            },
        );
        const data = (await response)?.data?.data;
        dispatch({
            type: CART_SUCCESS,
            payload: data,
          });
          return data;
        } catch (error) {
          dispatch({
            type: CART_FAILURE,
            error: error.message,
          });
        }
      }
};

export const updateCartData = (newData)  => async (dispatch) =>  {
  dispatch({
  type: CART_SUCCESS,
  payload: newData,
  });
}